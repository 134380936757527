import React, { SVGProps, useId } from 'react';

export const FeatureMagicStore = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 5 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 80 80" {...props}>
      <path
        d="M2.45549 45.9281C-0.818497 42.6541 -0.818497 37.3459 2.45549 34.0719L8.38357 28.1438L14.3117 34.0719C17.5856 37.3459 17.5856 42.6541 14.3117 45.9281C11.0377 49.2021 5.72948 49.2021 2.45549 45.9281Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M18.2637 61.7363C14.9897 58.4623 14.9897 53.1541 18.2637 49.8801L24.1918 43.952L45.9281 65.6883C49.2021 68.9623 49.2021 74.2705 45.9281 77.5445C42.6541 80.8185 37.3459 80.8185 34.0719 77.5445L18.2637 61.7363Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        d="M24.1918 12.3356L12.3356 24.1918L55.8082 67.6644L67.6644 55.8082L24.1918 12.3356Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <path
        d="M34.0719 14.3117C30.7979 11.0377 30.7979 5.72948 34.0719 2.45549C37.3459 -0.818498 42.6541 -0.818496 45.9281 2.45549L61.7363 18.2637C65.0103 21.5377 65.0103 26.8459 61.7363 30.1199L55.8082 36.0479L34.0719 14.3117Z"
        fill={`url(#${gradientIds[3]})`}
      />
      <path
        d="M65.6883 34.0719C62.4143 37.3459 62.4143 42.6541 65.6883 45.9281L71.6164 51.8562L77.5445 45.9281C80.8185 42.6541 80.8185 37.3459 77.5445 34.0719C74.2705 30.7979 68.9623 30.7979 65.6883 34.0719Z"
        fill={`url(#${gradientIds[4]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="60"
          y1="1.13993e-07"
          x2="39.3406"
          y2="79.8294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="60"
          y1="1.13993e-07"
          x2="39.3406"
          y2="79.8294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="60"
          y1="1.13993e-07"
          x2="39.3406"
          y2="79.8294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[3]}
          x1="60"
          y1="1.13993e-07"
          x2="39.3406"
          y2="79.8294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[4]}
          x1="60"
          y1="1.13993e-07"
          x2="39.3406"
          y2="79.8294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
