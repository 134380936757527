import React, { SVGProps, useId } from 'react';

export const FeatureStaking = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg viewBox="0 0 80 80" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7538 0.635307C35.7154 1.0564 34.7424 1.68805 33.9002 2.53024L18.3094 18.121C14.8923 21.5381 14.8923 27.0783 18.3094 30.4954L24.3217 36.5076L39.9999 20.8294L55.6782 36.5076L61.6904 30.4954C65.1075 27.0783 65.1075 21.5381 61.6904 18.121L46.0996 2.53024C43.5731 0.0036609 39.8691 -0.627982 36.7538 0.635307ZM18.3118 61.8787L33.9026 77.4694C34.7448 78.3116 35.7178 78.9433 36.7563 79.3644C39.8715 80.6277 43.5755 79.996 46.1021 77.4694L61.6928 61.8787C65.1099 58.4616 65.1099 52.9214 61.6928 49.5043L55.6806 43.4921L40.0023 59.1703L24.324 43.4921L18.3118 49.5043C14.8947 52.9214 14.8947 58.4616 18.3118 61.8787ZM33.9012 46.0988C30.5329 42.7305 30.5329 37.2694 33.9012 33.901C37.2695 30.5327 42.7306 30.5327 46.0989 33.901C49.4672 37.2694 49.4672 42.7305 46.0989 46.0988C42.7306 49.4671 37.2695 49.4671 33.9012 46.0988ZM2.52959 46.0988C-0.83872 42.7305 -0.83872 37.2694 2.52959 33.9011C5.89789 30.5328 11.359 30.5328 14.7273 33.9011C18.0956 37.2694 18.0956 42.7305 14.7273 46.0988C11.359 49.4671 5.89789 49.4671 2.52959 46.0988ZM65.2728 46.0988C61.9045 42.7305 61.9045 37.2694 65.2728 33.9011C68.6411 30.5328 74.1022 30.5328 77.4705 33.9011C80.8388 37.2694 80.8388 42.7305 77.4705 46.0988C74.1022 49.4671 68.6411 49.4671 65.2728 46.0988Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1="60"
          y1="-3.48239e-07"
          x2="44.2676"
          y2="80.8306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
