import React, { SVGProps } from 'react';

export const Coingeko = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M31.9726 15.9285C31.9866 19.0879 31.0633 22.1805 29.3197 24.8153C27.5761 27.45 25.0903 29.5086 22.1768 30.7306C19.2632 31.9526 16.0527 32.2833 12.9513 31.6807C9.84981 31.0782 6.99666 29.5695 4.75261 27.3455C2.50857 25.1214 0.974396 22.2819 0.344101 19.186C-0.286194 16.09 0.0156926 12.8767 1.21159 9.95233C2.40748 7.02796 4.44368 4.52387 7.06269 2.75671C9.68171 0.989546 12.7659 0.0386723 15.9253 0.0243273C20.1623 0.00553613 24.2332 1.67055 27.2426 4.65313C30.252 7.6357 31.9534 11.6916 31.9726 15.9285Z"
        fill="#8DC63F"
      />
      <path
        d="M30.7691 15.9318C30.7824 18.8533 29.9291 21.7131 28.3171 24.1496C26.705 26.5861 24.4067 28.4898 21.7127 29.6201C19.0187 30.7504 16.05 31.0564 13.1821 30.4995C10.3142 29.9426 7.6758 28.5478 5.6006 26.4914C3.52539 24.4351 2.10657 21.8095 1.52355 18.9468C0.940535 16.084 1.21951 13.1127 2.32519 10.4085C3.43087 7.70433 5.3136 5.38874 7.7353 3.75457C10.157 2.1204 13.0089 1.24104 15.9304 1.22769C17.8702 1.21883 19.7928 1.59214 21.5883 2.3263C23.3839 3.06045 25.0172 4.14109 26.3951 5.50649C27.773 6.8719 28.8685 8.49534 29.619 10.2841C30.3695 12.0729 30.7603 13.992 30.7691 15.9318Z"
        fill="#F9E988"
      />
      <path
        d="M16.267 2.18554C17.2061 2.01791 18.1674 2.01791 19.1065 2.18554C20.0546 2.33577 20.971 2.64295 21.8181 3.09445C22.6597 3.55731 23.3885 4.18513 24.1122 4.77761C24.836 5.37008 25.5581 5.96929 26.2448 6.62908C26.945 7.27781 27.5563 8.0164 28.0626 8.82561C28.5847 9.62935 29.0122 10.4907 29.3368 11.3924C29.9682 13.1936 30.148 15.1223 29.8602 17.0091H29.766C29.4887 15.2065 29.0251 13.4376 28.3824 11.7307C28.0744 10.8867 27.7118 10.0635 27.2968 9.2666C26.8606 8.47197 26.3808 7.70215 25.8594 6.96067C25.3275 6.21018 24.6758 5.55227 23.9304 5.01325C23.1814 4.47969 22.3197 4.13296 21.4848 3.80474C20.65 3.47652 19.8151 3.14999 18.9483 2.8992C18.0815 2.64841 17.1944 2.46158 16.2704 2.2798L16.267 2.18554Z"
        fill="white"
      />
      <path
        d="M23.4878 10.7141C22.4156 10.4027 21.2997 9.96339 20.1787 9.51904C20.1147 9.23627 19.8639 8.88449 19.359 8.45528C18.6234 7.81736 17.2433 7.83419 16.0499 8.11865C14.7337 7.80895 13.4326 7.69954 12.1786 7.99914C1.97025 10.81 7.76032 17.6824 4.01024 24.5833C4.5438 25.7144 10.2952 32.3259 18.6167 30.5468C18.6167 30.5468 15.7722 23.7081 22.1934 20.4259C27.4028 17.7547 31.1681 12.8096 23.4878 10.7141Z"
        fill="#8BC53F"
      />
      <path
        d="M24.7485 15.2098C24.7495 15.3325 24.714 15.4526 24.6467 15.5551C24.5793 15.6576 24.483 15.7378 24.37 15.7855C24.257 15.8332 24.1324 15.8463 24.012 15.8231C23.8915 15.7999 23.7807 15.7415 23.6935 15.6552C23.6063 15.569 23.5467 15.4588 23.5222 15.3386C23.4977 15.2184 23.5094 15.0937 23.5559 14.9802C23.6023 14.8667 23.6814 14.7695 23.7832 14.701C23.8849 14.6325 24.0047 14.5958 24.1274 14.5955C24.2085 14.595 24.2889 14.6106 24.364 14.6412C24.4391 14.6718 24.5075 14.717 24.5652 14.774C24.6228 14.8311 24.6687 14.8989 24.7002 14.9737C24.7316 15.0485 24.748 15.1287 24.7485 15.2098Z"
        fill="white"
      />
      <path
        d="M16.0499 8.11524C16.7939 8.1691 19.4852 9.04771 20.1787 9.51899C19.6047 7.83583 17.6539 7.61702 16.0499 8.11524Z"
        fill="#009345"
      />
      <path
        d="M16.7585 12.3602C16.7585 12.9261 16.5907 13.4793 16.2763 13.9498C15.9619 14.4204 15.515 14.7871 14.9921 15.0037C14.4693 15.2203 13.894 15.277 13.3389 15.1665C12.7838 15.0561 12.274 14.7836 11.8738 14.3835C11.4737 13.9833 11.2011 13.4734 11.0907 12.9184C10.9803 12.3633 11.037 11.788 11.2536 11.2652C11.4701 10.7423 11.8369 10.2954 12.3074 9.98101C12.778 9.6666 13.3312 9.49878 13.8971 9.49878C14.656 9.49878 15.3838 9.80025 15.9204 10.3369C16.457 10.8735 16.7585 11.6013 16.7585 12.3602Z"
        fill="white"
      />
      <path
        d="M15.9119 12.3855C15.9122 12.783 15.7946 13.1717 15.574 13.5024C15.3534 13.8332 15.0396 14.091 14.6724 14.2434C14.3052 14.3957 13.9011 14.4358 13.5111 14.3584C13.1212 14.2811 12.7629 14.0898 12.4817 13.8088C12.2005 13.5278 12.0089 13.1697 11.9312 12.7798C11.8535 12.3899 11.8932 11.9858 12.0453 11.6184C12.1974 11.2511 12.455 10.9372 12.7855 10.7163C13.116 10.4954 13.5046 10.3774 13.9022 10.3774C14.4348 10.3779 14.9454 10.5895 15.3222 10.966C15.6989 11.3424 15.911 11.8529 15.9119 12.3855Z"
        fill="#58595B"
      />
      <path
        d="M27.0628 16.4687C24.7485 18.0997 22.116 19.33 18.3828 19.33C16.6356 19.33 16.2822 17.4786 15.1275 18.3841C14.53 18.8537 12.4345 19.899 10.7597 19.8249C9.08497 19.7508 6.38349 18.7662 5.63449 15.2063C5.33657 18.7662 5.1834 21.3886 3.84697 24.3947C6.50973 28.6581 12.8569 31.9453 18.6167 30.5315C17.999 26.2091 21.776 21.9743 23.9052 19.8081C24.7098 18.9884 26.2616 17.6486 27.0628 16.4619V16.4687Z"
        fill="#8BC53F"
      />
      <path
        d="M26.9719 16.5764C26.2346 17.2105 25.4119 17.738 24.528 18.1434C23.6469 18.562 22.7283 18.8968 21.7844 19.1432C20.8418 19.3872 19.8555 19.5707 18.8574 19.4798C17.8593 19.3889 16.8376 19.0489 16.1795 18.3016L16.2115 18.2646C17.0261 18.7914 17.9569 18.9766 18.886 19.0035C19.8293 19.0309 20.7727 18.9524 21.6986 18.7695C22.6339 18.5746 23.549 18.2928 24.432 17.9279C25.3293 17.5853 26.1741 17.1184 26.9416 16.541L26.9719 16.5764Z"
        fill="#58595B"
      />
    </svg>
  );
};
