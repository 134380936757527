import React, { SVGProps, useId } from 'react';

export const FeatureBoost = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg viewBox="0 0 80 80" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7539 0.635307C36.7155 1.0564 35.7425 1.68805 34.9003 2.53024L3.48401 33.9466C0.115233 37.3153 0.115236 42.7772 3.48401 46.146C6.85279 49.5147 12.3147 49.5147 15.6834 46.146L41 20.8294L66.3117 46.1411C69.6805 49.5098 75.1424 49.5098 78.5112 46.1411C81.8799 42.7723 81.8799 37.3104 78.5111 33.9416L47.0997 2.53024C44.5732 0.0036609 40.8692 -0.627982 37.7539 0.635307ZM47.0123 33.8186L47.055 33.8617L47.0998 33.9061L62.7847 49.5911C66.1535 52.9598 66.1535 58.4217 62.7847 61.7905C59.4159 65.1592 53.9541 65.1592 50.5853 61.7905L41 52.2052L31.4148 61.7905C28.046 65.1592 22.5841 65.1592 19.2154 61.7905C15.8466 58.4217 15.8466 52.9598 19.2154 49.5911L41 27.8064L47.0123 33.8186ZM34.9012 77.4706C31.5329 74.1023 31.5329 68.6412 34.9012 65.2729L41 59.1741L47.0989 65.2729C50.4672 68.6412 50.4672 74.1023 47.0989 77.4706C43.7306 80.8389 38.2695 80.8389 34.9012 77.4706Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1="61"
          y1="2.22619e-07"
          x2="45.3103"
          y2="80.8366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
