import React, { SVGProps, useId } from 'react';

export const FeatureSwap = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg viewBox="0 0 80 80" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2791 49.4909C33.6962 52.908 33.6962 58.4482 30.2791 61.8653L24.2669 67.8775L8.60938 52.22L8.58862 52.2408L2.57639 46.2285C0.861718 44.5139 0.0074578 42.2646 0.0136134 40.0172C0.00918303 37.7721 0.863449 35.5256 2.57641 33.8127L8.58864 27.8004L8.60938 27.8212L33.9003 2.53024C37.2691 -0.838533 42.7309 -0.838531 46.0997 2.53024C49.4685 5.89902 49.4685 11.3609 46.0997 14.7297L20.8088 40.0206L30.2791 49.4909ZM49.6795 30.4984C46.2624 27.0813 46.2624 21.5411 49.6795 18.124L55.6917 12.1118L71.3764 27.7964L71.4138 27.7589L77.4261 33.7712C80.8432 37.1883 80.8432 42.7285 77.4261 46.1455L46.1021 77.4695C42.7334 80.8382 37.2715 80.8382 33.9027 77.4695C30.534 74.1007 30.534 68.6388 33.9027 65.27L59.1769 39.9958L49.6795 30.4984ZM33.9012 33.901C30.5329 37.2694 30.5329 42.7305 33.9012 46.0988C37.2695 49.4671 42.7306 49.4671 46.0989 46.0988C49.4672 42.7305 49.4672 37.2694 46.0989 33.901C42.7306 30.5327 37.2695 30.5327 33.9012 33.901Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1="60"
          y1="-1.00906e-07"
          x2="44.2586"
          y2="80.8294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
