import React, { SVGProps, useId } from 'react';

export const FeatureLaunchpad = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 3 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M33.9299 2.52508C37.2968 -0.841695 42.7556 -0.841694 46.1225 2.52508L55.4422 11.8445C42.0186 15.5077 29.2669 22.2946 18.3437 32.1999C17.4444 33.0154 16.0391 33.5721 14.2514 33.6491C9.45204 33.8556 4.68408 35.1807 0.375 37.6303C0.761661 36.2556 1.49583 34.958 2.57749 33.8764L33.9299 2.52508Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M77.4748 33.8764L68.1549 24.5568C64.4909 37.982 57.7021 50.7351 47.7939 61.659C46.9785 62.558 46.4217 63.9626 46.3443 65.7497C46.1364 70.549 44.8099 75.3165 42.3589 79.625C43.7372 79.2391 45.0383 78.504 46.1225 77.4199L77.4748 46.0686C80.8417 42.7018 80.8417 37.2432 77.4748 33.8764Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.42579 45.8742C1.09494 44.9105 1.06718 42.9355 2.48355 42.1026C6.18212 39.9275 10.3055 38.7504 14.4639 38.5714C17.0973 38.4581 19.7013 37.6204 21.6539 35.8498C33.0392 25.5254 46.5179 18.7859 60.6361 15.6314C62.896 15.1264 64.8741 17.1045 64.3692 19.3643C61.214 33.484 54.4727 46.964 44.1454 58.3499C42.3751 60.3017 41.5371 62.9046 41.423 65.5371C41.243 69.6929 40.066 73.8136 37.8921 77.51C37.0591 78.9263 35.0841 78.8985 34.1204 77.5677L31.1271 73.4343C29.9808 71.8513 28.3133 70.7477 26.4939 70.0337C22.8632 68.609 19.4599 66.4306 16.5245 63.4986L16.5008 63.4749C13.5683 60.5392 11.3897 57.1355 9.96487 53.5043C9.25087 51.6847 8.14722 50.0171 6.56399 48.8707L2.42579 45.8742ZM43.7117 29.4772C45.6659 28.4666 48.0494 28.8368 49.6051 30.3924C51.1614 31.9486 51.5312 34.3331 50.5194 36.2875L48.9478 39.3234C47.0379 43.0129 42.0915 43.78 39.1538 40.8423C36.2153 37.9039 36.9837 32.9562 40.675 31.0474L43.7117 29.4772Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="60.0938"
          y1="1.13458e-07"
          x2="39.5312"
          y2="79.4552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="60.0938"
          y1="1.13458e-07"
          x2="39.5312"
          y2="79.4552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="60.0938"
          y1="1.13458e-07"
          x2="39.5312"
          y2="79.4552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
