import React, { SVGProps } from 'react';

export const Coinmarketcap = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#2B6AFF"
      />
      <path
        d="M19.6372 14.671C19.4623 14.9421 19.2874 15.2175 19.1169 15.4885C18.4656 16.5158 17.8404 17.5563 17.2066 18.5923C17.0055 18.9202 16.765 19.2175 16.4503 19.4492C15.4885 20.1574 14.4962 19.4404 14.3825 18.6011C14.3038 18.059 14.2557 17.5126 14.247 16.9661C14.2426 16.3322 14.247 15.6983 14.247 15.0688C14.247 14.977 14.2383 14.8852 14.2339 14.7934C14.1902 14.7934 14.1858 14.8284 14.1727 14.8459C12.9749 16.8262 11.7158 18.7716 10.4918 20.7344C10.4175 20.8524 10.3475 20.9748 10.2689 21.0929C10.2339 21.1453 10.2339 21.1803 10.282 21.2328C11.5191 22.5136 12.988 23.3792 14.7628 23.6503C16.918 23.9825 18.8765 23.4973 20.6295 22.1945C20.8437 22.0371 21.0361 21.8535 21.2459 21.6874C21.5038 21.4863 21.788 21.3639 22.129 21.4426C22.177 21.4513 22.2164 21.4732 22.2514 21.5038C22.3301 21.5825 22.4087 21.6524 22.4962 21.7224C22.7585 21.9147 22.7716 22.1727 22.7016 22.4568C22.6142 22.7934 22.3825 23.0251 22.1246 23.235C20.9355 24.2229 19.5978 24.9268 18.0765 25.2459C15.165 25.8623 12.5115 25.2765 10.1727 23.436C8.38907 22.0328 7.25683 20.1967 6.75847 17.9759C6.50055 16.8262 6.47432 15.6634 6.64918 14.4962C7.18251 10.8677 9.77049 8.11801 12.6689 7.01638C13.6044 6.65791 14.588 6.46118 15.5891 6.43058C16.682 6.39561 17.753 6.49178 18.8022 6.8284C20.2011 7.26993 21.4776 8.03058 22.5311 9.04916C23.9869 10.4568 24.9049 12.1574 25.2896 14.1464C25.4776 15.1169 25.5213 16.0962 25.3639 17.071C25.176 18.247 24.2229 19.4142 22.9333 19.659C21.6568 19.9038 20.4678 19.2437 19.9519 17.9978C19.729 17.4601 19.6678 16.8962 19.6678 16.3235C19.6678 15.7945 19.6678 15.2699 19.6678 14.741C19.6678 14.7235 19.6678 14.706 19.6634 14.6885L19.6372 14.671Z"
        fill="#FEFEFE"
      />
      <path
        d="M15.9738 17.2197V16.4284C15.9738 15.3399 15.9781 14.247 15.965 13.1585C15.9606 12.7169 15.8601 12.2973 15.5366 11.965C15.2437 11.6721 14.8022 11.5891 14.4219 11.7508C14.1596 11.8645 13.9366 12.0525 13.7749 12.2929C13.3902 12.8394 13.0667 13.4251 12.7038 13.9847C11.5322 15.7727 10.4393 17.6088 9.28524 19.4055C9.19344 19.5497 9.11475 19.5541 9.03606 19.3924C8.93989 19.2 8.85682 19.0033 8.77814 18.8022C8.44152 17.9541 8.26666 17.0536 8.25355 16.1399C8.22732 14.2339 8.76065 12.5071 9.96284 11.0208C11.1432 9.56067 12.6601 8.61203 14.5137 8.27105C17.1934 7.78143 19.5191 8.52897 21.4557 10.4219C22.6623 11.5978 23.3617 13.0579 23.6415 14.7235C23.7464 15.3355 23.7683 15.9607 23.7071 16.5771C23.659 17.0317 23.4754 17.4295 23.1126 17.7224C22.8109 17.9629 22.4699 18.0984 22.0896 17.9454C21.6874 17.788 21.482 17.4601 21.412 17.0404C21.3115 16.4284 21.3246 15.812 21.3377 15.1956C21.3464 14.671 21.3814 14.1377 21.2678 13.6175C21.1322 13.0142 20.7825 12.5858 20.1574 12.4284C19.8557 12.3541 19.5759 12.4547 19.3137 12.5945C18.9158 12.8088 18.6142 13.1323 18.3606 13.4951C17.906 14.1377 17.5126 14.8241 17.0929 15.4929C16.7432 16.0525 16.3934 16.612 16.0437 17.1716C16.0306 17.1891 16.0175 17.2109 16.0044 17.2284L15.9738 17.2197Z"
        fill="#2B6AFF"
      />
    </svg>
  );
};
