import React, { SVGProps } from 'react';

export const Bybit = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <rect x="-1.52588e-05" width="32" height="32" rx="16" fill="#181B25" />
      <path d="M20.9688 17.3402V11.3401H22.1758V17.3402H20.9688Z" fill="#F7A600" />
      <path
        d="M6.91083 19.0102H4.32527V13.01H6.80601C8.01303 13.01 8.715 13.6675 8.715 14.6967C8.715 15.3637 8.26396 15.7925 7.95268 15.9354C8.32431 16.1038 8.80394 16.4818 8.80394 17.2854C8.80394 18.4035 8.01303 19.0102 6.91083 19.0102ZM6.71389 14.055H5.53546V15.4367H6.71389C7.22529 15.4367 7.51116 15.1572 7.51116 14.7443C7.51116 14.3314 7.22529 14.055 6.71389 14.055ZM6.79013 16.4881H5.53229V17.962H6.79013C7.33646 17.962 7.59692 17.6253 7.59692 17.2187C7.59692 16.8121 7.33646 16.4881 6.79013 16.4881Z"
        fill="white"
      />
      <path
        d="M12.4822 16.5485V19.0102H11.2847V16.5485L9.42651 13.01H10.7352L11.8882 15.4272L13.0253 13.01H14.334L12.4822 16.5485Z"
        fill="white"
      />
      <path
        d="M17.7581 19.0102H15.1725V13.01H17.6533C18.8603 13.01 19.5623 13.6675 19.5623 14.6967C19.5623 15.3637 19.1112 15.7925 18.7999 15.9354C19.1716 16.1038 19.6512 16.4818 19.6512 17.2854C19.648 18.4035 18.8603 19.0102 17.7581 19.0102ZM17.558 14.055H16.3796V15.4367H17.558C18.0694 15.4367 18.3553 15.1572 18.3553 14.7443C18.3553 14.3314 18.0694 14.055 17.558 14.055ZM17.6342 16.4881H16.3764V17.962H17.6342C18.1806 17.962 18.441 17.6253 18.441 17.2187C18.441 16.8121 18.1837 16.4881 17.6342 16.4881Z"
        fill="white"
      />
      <path
        d="M26.0611 14.055V19.0102H24.8541V14.055H23.2437V13.01H27.6747V14.055H26.0611Z"
        fill="white"
      />
    </svg>
  );
};
