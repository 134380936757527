import React, { SVGProps } from 'react';

export const Bscscan = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <ellipse cx="16" cy="15.8885" rx="16" ry="15.8885" fill="white" />
      <path
        d="M6.65829 15.1037C6.65831 14.9261 6.69366 14.7501 6.76232 14.586C6.83098 14.422 6.93156 14.273 7.05832 14.1476C7.18509 14.0223 7.33553 13.923 7.50099 13.8556C7.66645 13.7882 7.84369 13.7539 8.02254 13.7548L10.2843 13.7621C10.6449 13.7621 10.9908 13.9044 11.2458 14.1578C11.5008 14.4112 11.6441 14.7549 11.6441 15.1132V23.6116C11.8988 23.5365 12.2248 23.4565 12.5836 23.3726C12.8321 23.3145 13.0536 23.1747 13.2122 22.9758C13.3707 22.7769 13.457 22.5307 13.4571 22.277V11.7361C13.4571 11.3778 13.6003 11.0341 13.8553 10.7807C14.1103 10.5273 14.4562 10.3849 14.8168 10.3848H17.0855C17.4461 10.3849 17.792 10.5273 18.047 10.7807C18.302 11.0341 18.4452 11.3778 18.4452 11.7361V21.5197C18.4452 21.5197 19.0128 21.2915 19.5653 21.0597C19.7706 20.9734 19.9458 20.829 20.069 20.6444C20.1923 20.4598 20.2581 20.2433 20.2583 20.0218V8.35829C20.2583 8.00003 20.4015 7.65642 20.6564 7.40304C20.9114 7.14967 21.2572 7.00729 21.6177 7.00721H23.884C24.2444 7.00758 24.5899 7.15006 24.8446 7.40341C25.0994 7.65676 25.2425 8.00021 25.2425 8.35829V17.9628C27.2073 16.5479 29.1985 14.8462 30.7787 12.8C31.0078 12.503 31.1596 12.1542 31.2203 11.7848C31.2808 11.4153 31.2486 11.0367 31.1261 10.6828C30.3946 8.59179 29.2318 6.67554 27.7123 5.05668C26.1927 3.43782 24.3497 2.15197 22.3013 1.28142C20.2529 0.410883 18.0441 -0.0251875 15.8164 0.00112353C13.5886 0.0274345 11.3909 0.515546 9.36395 1.43422C7.33693 2.3529 5.52526 3.68191 4.04484 5.33621C2.56443 6.9905 1.44788 8.93367 0.766642 11.0413C0.0854028 13.149 -0.145521 15.3748 0.0886476 17.5762C0.322816 19.7776 1.01692 21.9063 2.12654 23.8258C2.31986 24.157 2.60428 24.4264 2.94639 24.6025C3.28848 24.7786 3.67405 24.8541 4.05783 24.8199C4.48653 24.7824 5.0203 24.7294 5.6549 24.6555C5.93108 24.6243 6.18608 24.4932 6.37129 24.2872C6.55652 24.0813 6.65905 23.8148 6.65934 23.5386V15.1037"
        fill="#181B25"
      />
      <path
        d="M6.56762 28.7329C8.9565 30.4596 11.7796 31.4962 14.7245 31.7275C17.6695 31.9591 20.6215 31.3765 23.2539 30.0446C25.8864 28.7127 28.0967 26.683 29.6402 24.1804C31.1837 21.6778 32.0005 18.7996 32 15.8644C32 15.4972 31.9828 15.1358 31.9583 14.7754C26.1075 23.4456 15.3046 27.4988 6.56762 28.7329Z"
        fill="#F0B90B"
      />
    </svg>
  );
};
